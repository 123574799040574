import {
  getModelTypeByCode,
  getServiceLogo,
  getServiceOperation,
  getTypeServiceName,
} from "../helpers/utils";
import { typeTicketCode } from "../helpers/constants";

export const shoppingMixin = {
  props: {
    shopping: {
      type: Object,
      required: true,
    },
  },

  computed: {
    canCreateTicket() {
      return this.shopping.status !== "Echec";
    },

    activeClass() {
      switch (this.shopping.status) {
        case "Succès":
          return "success";
        case "Echec":
          return "danger";
        case "En cours":
          return "info";
      }
    },
    statusClassIcon() {
      switch (this.shopping.status) {
        case "Succès":
          return "fas fa-thumbs-up text-success";
        case "Echec":
          return "fas fa-thumbs-down text-danger";
        case "En cours":
          return "fas fa-hourglass-half text-info";
      }
    },
    serviceLabel() {
      return this.shopping.users_service.service.label;
    },
    serviceLogo() {
      return getServiceLogo(this.shopping.users_service);
    },

    operation() {
      return getServiceOperation(this.shopping.users_service);
    },

    typeServiceName() {
      return getTypeServiceName(
        this.shopping.users_service.service.type_service
      );
    },
  },
  methods: {
    openTicketFromThis() {
      const query = {
        model_type: getModelTypeByCode(typeTicketCode.shopping),
        model_id: this.shopping.id,
        service_id: this.shopping.users_service.services_id,
        api_code: this.shopping.api_code,
        type: this.shopping.users_service.service.type_service.code
          .toString()
          .toLowerCase(),
      };
      this.$router.push({ name: "assistance", query: query });
    },
  },
};
