<template>
  <div>
    <div class="md-modal md-effect-11 md-show">
      <div class="md-content">
        <h3 class="bg-dark">
          {{ typeServiceName }} -
          <span class="f-16 d-inline d-sm-none">{{ shopping.reference }}</span>
          <span class="d-none d-sm-inline">{{ shopping.reference }}</span>
        </h3>
        <div class="card-body p-0">
          <table class="table table-striped table-hover">
            <tbody>
              <tr>
                <td class="align-middle text-right">
                  <i>{{ $t("common.service") }}</i>
                </td>
                <td class="align-middle text-left font-weight-bold">
                  {{ serviceName }}
                </td>
              </tr>
              <tr v-if="canShowIdentification">
                <td class="align-middle text-right">
                  <i>
                    {{ $t("common.identifier") }}
                  </i>
                </td>
                <td class="align-middle text-left font-weight-bold">
                  {{ operation.identify | truncate(20, "...") }}
                </td>
              </tr>
              <template v-if="operation.creditCard">
                <tr>
                  <td class="align-middle text-right">
                    <i>{{ $t("common.name_and_surname") }}</i>
                  </td>
                  <td class="align-middle text-left font-weight-bold">
                    {{ operation.creditCard.nom }}
                  </td>
                </tr>
              </template>
              <tr>
                <td class="align-middle text-right">
                  <i>{{ $t("common.amount") }}</i>
                </td>
                <td class="align-middle text-left font-weight-bold">
                  {{ shopping.amount + " " + shopping.currency }}
                </td>
              </tr>
              <template v-if="operation.travel">
                <tr>
                  <td class="align-middle text-right">
                    <i>{{ $t("common.description") }}</i>
                  </td>
                  <td class="align-middle text-left font-weight-bold">
                    {{ $t("common.ticket") }}
                    {{
                      ` ${operation.travel.standing_trip} - ${operation.travel.passenger} `
                    }}
                    {{ $t("common.passenger_s") }}
                    <br />
                    {{ `${operation.travel.departure_city} ` }}
                    {{ $t("common.for") }}
                    {{ ` ${operation.travel.arrival_city}` }}
                  </td>
                </tr>
                <tr>
                  <td class="align-middle text-right">
                    <i>{{ $t("common.departure") }}</i>
                  </td>
                  <td class="align-middle text-left font-weight-bold">
                    {{ operation.travel.date | moment("calendar") }}
                  </td>
                </tr>
              </template>
              <tr v-if="shopping.users_service.visa_card">
                <td class="align-middle text-right">
                  {{ $t("common.description") }}
                  <i class="d-none d-sm-inline">{{ $t("common.visa_card") }}</i>
                </td>
                <td class="align-middle text-left font-weight-bold p-0">
                  <ul class="list-group bg-transparent p-0">
                    <li class="list-group-item">
                      <span class="fas fa-user-check pl-2 m-r-5" />
                      {{ shopping.users_service.visa_card.name }}
                    </li>
                    <li class="list-group-item">
                      <span class="fas fa-phone pl-2 m-r-5" />
                      {{ shopping.users_service.visa_card.phone }}
                    </li>
                    <li class="list-group-item">
                      <span class="fas fa-map-marker pl-2 m-r-5" />
                      {{ shopping.users_service.visa_card.ville }} -
                      {{ shopping.users_service.visa_card.quartier }}
                    </li>
                    <li class="list-group-item">
                      <span class="fas fa-at pl-2 m-r-5" />
                      {{ shopping.users_service.visa_card.email }}
                    </li>
                    <li class="list-group-item">
                      <span class="pl-2 m-r-5">
                        {{ shopping.users_service.visa_card.identify_type }}
                      </span>
                      : {{ shopping.users_service.visa_card.identify_value }}
                    </li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td class="align-middle text-right">
                  <i class="d-inline d-sm-none">{{ $t("common.date") }}</i>
                  <i class="d-none d-sm-inline">
                    {{ $t("common.creation_date") }}
                  </i>
                </td>
                <td class="align-middle text-left font-weight-bold">
                  {{ shopping.created_at | moment("DD MMMM YYYY") }}
                  <small class="d-block">
                    <i class="fa fa-clock f-w-300"></i>
                    {{ shopping.created_at | moment("H:m:s") }}
                  </small>
                </td>
              </tr>
              <tr>
                <td class="align-middle text-right">
                  <i>{{ $t("common.status") }}</i>
                </td>
                <td class="align-middle text-left font-weight-bold">
                  <i :class="statusClassIcon"></i> {{ shopping.status }}
                </td>
              </tr>
              <tr>
                <BaseCreateTicketLine
                  v-if="canCreateTicket"
                  @click="openTicketFromThis"
                />
              </tr>
            </tbody>
          </table>
          <div class="card-footer text-center">
            <button
              class="btn btn-primary"
              role="button"
              type="button"
              @click.prevent="close"
            >
              {{ $t("common.close") }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="md-overlay" @click.prevent="close"></div>
  </div>
</template>

<script>
import { shoppingMixin } from "../../mixins/shoppingMixin";

export default {
  name: "ShoppingDetails",
  mixins: [shoppingMixin],
  methods: {
    close() {
      this.$router.back();
    },
  },
  computed: {
    serviceName() {
      if (this.operation.travel) return this.operation.travel.name_agency;
      else {
        if (this.operation.creditCard) return this.operation.creditCard.bank;
        else return this.serviceLabel;
      }
    },
    canShowIdentification() {
      return !this.operation.travel;
    },
  },
};
</script>

<style scoped></style>
